.selected-language {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 8px; */
  padding: 0 8px;
  background: white;
  cursor: pointer;
  border: 1px solid rgb(246, 242, 242);
  height: 45px;
  gap: 4px
}

.translate-icon {
  font-size: 20px;
  aspect-ratio: inherit;
}

.dropdown-icon {
  font-size: 20px;
  aspect-ratio: inherit;
}

.vertical-icon {
  font-size: 20px;
  color: black;
}

.selected-language-text {
  font-weight: 500;
  font-size: 14px;
  font-family: 'Inter'

}

.lang-parent {
  position: absolute;
  top: 50px;
  z-index: 1000;
  right: 0px;
  background: white;
  width: 150px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.lang-list {
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  width: 100%;
  padding: 3px 15px;
  font-size: 14px;
  font-family: 'IBM Plex Sans'
}

.lang-list:hover {
  background-color: #dad8d8;
}
