.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
}

.notification-btn {
    padding: 8px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
.notification-btn-allow {
  background: #4caf50;
  color: #fff;
}

.notification-btn-close {
  background: #f44336;
  color: #fff;
}

.notification-btn:hover {
  opacity: 0.9;
}
