.flex-nowrap {
	flex-wrap: nowrap !important;
	-ms-flex-wrap: nowrap !important;
}
.whitespace-nowrap {
	white-space: nowrap !important;
}
.scrollbar-hidden {
	&::-webkit-scrollbar {
		display: none !important;
	}
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
}
.scroll-x {
	overflow-x: auto !important;
	overflow-y: hidden !important;
}
.color-black {
	color: black !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-bold {
	font-weight: bold !important;
}
.justify {
	text-align: justify !important;
}
.pre-line {
	white-space: pre-line !important;
}
.height-full-content {
	height: calc(100vh - 85px - 10px);
}
.text-avendi-gray {
	color: #3E3E3E !important;
}
.h3-small {
	font-size: 1.4rem !important;
}
.dynamic_logo {
	max-height: 55px !important;
	width: auto !important;

  &--large {
    max-height: 65px !important;
  }
}
.pointer-events-none {
	pointer-events: none;
}
.btn-transparent {
	background: none !important;
	outline: none !important;
	border: none !important;
	&:focus {
		box-shadow: none !important;
	}
}
.fw-normal {
  font-weight: normal !important;
}
.br-10 {
  border-radius: 10px !important;
}
.card-hovered:hover {
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.2);
}
.no-fieldset {
  fieldset {
    display: none;
  }
}
.full-width-material-input {
  .MuiTextField-root {
    width: 100% !important;
  }
}
.font-0dot9rem {
  font-size: 0.9rem;
}
.color-inherit {
  color: inherit !important;
}
.fs-inherit {
  font-size: inherit !important;
}
@media all and (max-width: 426px) {
	.xs-full-width {
		display: block;
		width: 100%;
	}
}

/* Card */
.avendi-card {
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  margin-top: 15px;
}
