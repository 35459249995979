.reply-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px !important;
}

.message {
    padding: 10px 15px !important;
    border-radius: 5px;
    max-width: 60%;
}

.message.staff {
    background-color: #e0f7fa;
    align-self: flex-start;
    text-align: right;
    /* width: 100%; */

}

.message.guest {
    background-color: #ffffff;
    align-self: flex-end;
    text-align: left;
    /* width: 100%; */
}

.message-content {
    margin-bottom: 5px;
    font-size: 14px;
    color: #534f4f;
}

.message-sender {
    padding-top: 5px !important;
    font-size: 11px;
    color: black;
}

.show-messages {
    text-decoration: underline;
    cursor: pointer;
    width: 130px;
}