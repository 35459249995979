#page__request-status-details {
  .ls-1dot5 {
    letter-spacing: 1.5px;
  }

  .font-small {
    font-size: 13px !important;
  }

  .instruction {
    font-size: 12px !important;
  }

  .text-spaced-large {
    letter-spacing: 1.5px;
    color: black;
    font-weight: normal;
    font-size: 13px;
  }

  button {
    min-width: 135px;
  }
}

@keyframes chat-animation  {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}