@import 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #8f8f8f;
  background-color: #f5f5f5;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  text-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #8f8f8f;
  line-height: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1 {
  font-weight: 700;
  color: #000000;
  font-size: 18px;
}
h4 {
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
.card_background{
  background-color: #FBFBFB !important
}
button {
  &:focus {
    outline: none !important;
  }
}
input {
  &:focus {
    outline: none !important;
  }
}
textarea {
  &:focus {
    outline: none !important;
  }
}
select {
  &:focus {
    outline: none !important;
  }
}
label {
  display: initial;
  margin-bottom: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.ha_button {
  input {
    display: inline-block;
    cursor: pointer;
    border: none;
    background: #333333;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    padding: 10px 15px;
    border-radius: 7px;
  }
  button {
    display: inline-block;
    cursor: pointer;
    border: none;
    background: #333333;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    padding: 10px 15px;
    border-radius: 7px;
  }
  a {
    display: inline-block;
    cursor: pointer;
    border: none;
    background: #333333;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    padding: 10px 15px;
    border-radius: 7px;
  }
}

.ha_goback {
  input {
    display: inline-block;
    cursor: pointer;
    border: none;
    background: #333333;
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    padding: 7px 10px;
    border-radius: 4px;
  }
  // button {
  // 	display: inline-block;
  // 	cursor: pointer;
  // 	border: none;
  // 	background: #333333;
  // 	color: #ffffff;
  // 	font-weight: 400;
  // 	font-size: 16px;
  // 	line-height: normal;
  // 	padding: 10px 15px;
  // 	border-radius: 7px;
  // }
  // a {
  // 	display: inline-block;
  // 	cursor: pointer;
  // 	border: none;
  // 	background: #333333;
  // 	color: #ffffff;
  // 	font-weight: 400;
  // 	font-size: 16px;
  // 	line-height: normal;
  // 	padding: 10px 15px;
  // 	border-radius: 7px;
  // }
}

.popup_modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0px;
  top: 0;
  left: 0;
  background-color: rgba(78, 75, 75, 0.8);
  z-index: 1;
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20.8px;
  }
  p {
    font-size: 12px;
    line-height: 20.6px;
    color: #333333;
  }
}
.modal_bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  // background-color: #5a5555;
  // background-color: #ff0909;
  // opacity: 80%;
  -webkit-tap-highlight-color: transparent;
  z-index: 2;
}
.modal_content {
  z-index: 3;
  border-radius: 7px;
  // margin: 0 10px;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: -150px;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 400px;
}
.login_input {
  input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    background: transparent !important;
    padding: 9px 0px;
    border: 0px !important;
    border-bottom: 1px solid #E4E4E4 !important;
    resize: none;
    &::-webkit-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::placeholder {
      color: #bababa;
      opacity: 1;
    }
    &:focus {
      border: 0px !important;
      border-bottom: 1px solid black !important;

    }
  }}
.ha_login_input {
  input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    background: #ebebeb !important;
    padding: 12px 15px;
    margin: 7px 0px;
    border: 1px solid #ebebeb !important;
    border-radius: 5px;
    resize: none;
    &::-webkit-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #bababa;
      opacity: 1;
    }
    &::placeholder {
      color: #bababa;
      opacity: 1;
    }
    &:focus {
      border: 1px solid #ccc;
    }
  }
  textarea {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    background: #ebebeb !important;
    padding: 12px 15px;
    margin: 7px 0px;
    border: 1px solid #ebebeb !important;
    border-radius: 5px;
    resize: none;
    &::placeholder {
      color: #bababa;
      opacity: 1;
    }
    &:focus {
      border: 1px solid #ccc;
    }
  }
  select {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    background: #ebebeb !important;
    padding: 12px 15px;
    margin: 7px 0px;
    border: 1px solid #ebebeb !important;
    border-radius: 5px;
    resize: none;
  }
}
.cs_mask {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
  left: -9999px;
}
.cs_nav_toggle {
  cursor: pointer;
  height: 35px;
  width: 35px;
  outline: none;
  border: 0;
  background: none;
  z-index: 9999;
  .cs_ico {
    width: 45px !important;
    cursor: pointer;
    border-radius: 5px;
    height: 4px;
    width: 35px;
    background: #161616;
    position: absolute;
    left: 10px;
    display: block;
    content: '';
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;

    &.left_aligned {
      left: 0 !important;
    }

    &::before {
      cursor: pointer;
      border-radius: 5px;
      height: 4px;
      width: 35px;
      background: #161616;
      position: absolute;
      left: 0px;
      display: block;
      content: '';
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
    }
    &::after {
      cursor: pointer;
      border-radius: 5px;
      height: 4px;
      width: 35px;
      background: #161616;
      position: absolute;
      left: 0px;
      display: block;
      content: '';
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  }
}
.cs_nav_toggle.cs_cross_active {
  position: absolute;
  left: 35%;

  @media screen and (min-width: 768px) {
    left: 340px;
  }

  .cs_ico {
    &:before {
      top: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      top: 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    background-color: transparent;
  }
}
.pr_nav_bar {
  display: none;
}
.header_area {
  position: relative;
  background: #fafafa;
  width: 100%;
  padding: 10px 10px;
}
.header-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer.footer_center_image {
  position: absolute;
  bottom: 25px;
  left: calc(100vw / 2 - 45px);
  img {
    max-width: 90px;
    height: auto;
  }
}
.logo {
  &--centered {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-right: 20px;
  }

  position: relative;
  padding-left: 25px;

  img {
    position: relative;
    z-index: 999;
  }
}
.logo_add_class {
  width: 100%;
  text-align: right;
}
.menu {
  display: none;
  background: #fafafa;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  padding-top: 140px;
  max-width: 400px;
  z-index: 99;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    li {
      a {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        color: #161616;
        border-bottom: 1px solid #ebebeb;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        position: relative;
        padding-top: 15px;
        padding-left: 70px;
        padding-bottom: 15px;
        &:hover {
          background: #f5f5f5;
          &::after {
            position: absolute;
            content: '';
            right: 2px;
            top: 0;
            width: 10px;
            height: 100%;
            background: #ffffff;
            border-radius: 20px;
          }
        }
      }
      &:last-child {
        a {
          border-bottom: none !important;
        }
      }
    }
  }
}
.menu_bottom {
  background: #282626;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0px;
  padding-left: 50px;
  width: 80%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  position: absolute;
  bottom: 40px;
  left: 0;
  h4 {
    margin-right: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  img {
    width: 50px;
    margin-top: -3px;
  }
}
.mtabs_items_area {
  padding-top: 20px;
  padding-bottom: 10px;
}

.tabs_menu {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      padding-right: 20px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      a {
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
        color: #8f8f8f;
        line-height: normal;
        position: relative;
        padding: 10px 0px;
      }
      &:hover {
        a {
          color: black;
        }
      }
    }
  }
  .ui-tabs-active.ui-state-active {
    a {
      color: #ffffff;
      background: #000;
      padding: 5px 15px;
      border-radius: 20px;
      bottom: 0;
      // margin: 0 8px;

      // top: 3px;

      // &::after {
      // 	position: absolute;
      // 	content: '';
      // 	background: #000;
      // 	left: 50%;
      // 	-webkit-transform: translateX(-50%);
      // 	-ms-transform: translateX(-50%);
      // 	transform: translateX(-50%);
      // 	bottom: 0;
      // 	width: 100%;
      // 	height: 2px;
      // }
    }
  }
  .home_tab{

    .ui-tabs-active.ui-state-active {
      a {
        color: black;
        text-transform: none !important;
    background-color: #00d562;
  
      }
    }
  }
  .arrow {
    position: absolute;
    font-size: 30px;
    top: -11px;
    font-weight: bold;
    color: #8f8f8f;
    &:hover {
      color: black;
    }
  }
  .arrow.btn {
    &:focus {
      box-shadow: none;
    }
  }
  .arrow.arrow-left {
    left: -40px;
  }
  .arrow.arrow-right {
    right: -40px;
  }
}

.home_tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    span{
      color: black ;
      font-size: 14px;
    }
  }
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      padding-right: 20px;
      letter-spacing: 1.5px;
      a {
        display: inline-block;
        font-weight: 500;
        font-size: 39px;
        color: #DFDFDF;
        line-height: normal;
        letter-spacing: -2.21px;
        position: relative;
        // padding: 10px 0px;
      }
      &:hover {
        a {
          color: black;
        }
      }
    }
  }

  .ui-tabs-active.ui-state-active {
    a {
      color: black;

      // margin: 0 8px;

      // top: 3px;

      &::after {
      	position: absolute;
      	content: '';
      	background: #000;
      	left: 50%;
      	-webkit-transform: translateX(-50%);
      	-ms-transform: translateX(-50%);
      	transform: translateX(-50%);
      	bottom: -10px;
        // top:px;
        border-radius: 100px;
      	width: 7px;
      	height: 7px;
      }
    }
  }

  .arrow {
    position: absolute;
    font-size: 30px;
    top: -11px;
    font-weight: bold;
    color: #8f8f8f;
    &:hover {
      color: black;
    }
  }
  .arrow.btn {
    &:focus {
      box-shadow: none;
    }
  }
  .arrow.arrow-left {
    left: -40px;
  }
  .arrow.arrow-right {
    right: -40px;
  }
}


.tabs_menu.new_page_menu {
  .ui-tabs-active.ui-state-active {
    a {
      &::after {
        width: 70%;
      }
    }
  }
  overflow: hidden;
}
.all_tabs_items {
  .col-6 {
    padding-top: 35px;
  }
}
.single_tabs_items {
  background: #f6f6f6;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 60px 20px;
  border-radius: 7px;
  position: relative;
  height: 100%;
}
.hotels_gallery {
  .single_tabs_items {
    padding: 20px 20px 40px 20px;
  }
  .tabs_items_img {
    img {
      width: 180px;
      height: 140px;
      object-fit: initial;
    }
  }
}
.tabs_items_img {
  text-align: center;
  img {
    width: 130px;
    height: 120px;
    -o-object-fit: contain;
    object-fit: contain;
  }
  h4 {
    padding-top: 15px;
  }
}
.tabs_items_icon {
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(4, 4, 4, 0.15);
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  z-index: 9;
  span {
    background: #000;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 15px 5px #fff;
    box-shadow: 0px 0px 15px 5px #fff;
    img {
      width: 14px;
    }
  }
}
.logo {
  &.pl_logo {
    padding-left: 35px;
  }
}
.right_angle {
  a {
    color: #161616;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-45%);
    -ms-transform: translateY(-45%);
    transform: translateY(-45%);
    left: 0;
    font-size: 40px;
    line-height: normal;
  }
}
.cart {
  position: relative;
  padding-right: 5px;
  img {
    position: relative;
    width: 30px;
    height: 30px;
  }
  p {
    position: absolute;
    background: #f61414;
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
    line-height: 15px;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 100%;
    top: -5px;
    right: 0;
  }
}
.new_page_tabs_area {
  padding-top: 30px;
  > h1 {
    font-weight: 600;
    padding-left: 20px;
  }
}
.new_page_items_box {
  margin-top: 20px;
}
.items_padding {
  padding: 6px 0px;
}
.box_shadow {
  padding: 20px 20px;
  border-radius: 7px;
  background: #fff;
}
.new_page_items {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.new_page_left {
  h1 {
    font-weight: 600;
  }
  p {
    max-width: 340px;
    padding: 20px 0px;
  }
}
.time_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h3 {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    padding-left: 15px;
  }
}
.new_page_right {
  img {
    width: 100px;
    height: auto;
    border-radius: 10px;
    margin-left: 10px;
  }
}
.single_page_area {
  overflow: hidden;
  padding-bottom: 80px;
}
.single_fbanner {
  img {
    width: 100%;
    border-radius: 10px;
    margin-top: -40px;
  }
}
.single_page_box {
  padding-top: 20px;
}
.services_box {
  padding: 30px 0px;
}
.museum_box {
  padding: 30px 0px;
}
.items_gap {
  padding: 5px 0px;
}
.single_page {
  overflow: hidden;
}
.single_page_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  h1 {
    &:first-child {
      font-weight: 500;
    }
    &:last-child {
      font-size: 14px;
    }
  }
  p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.add_class2 {
  textarea {
    margin-top: 25px;
    margin-bottom: 8px;
    width: 100%;
    padding: 10px 15px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    color: #8f8f8f;
    font-weight: 500;
    font-size: 16px;
    resize: none;
    &::placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &:focus {
      border: 1px solid #d8d8d8;
    }
  }
}
.page_list {
  ul {
    padding-top: 5px;
    padding-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: #d8d8d8;
      width: 150%;
      height: 1px;
    }
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: #d8d8d8;
      width: 150%;
      height: 1px;
    }
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      color: #000;
      padding: 15px 0px;
    }
  }
}
.quantity_counter_area {
  padding-top: 20px;
}
.quantity_counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 120px;
  margin: 0 auto;
  .increment {
    border: 0;
    cursor: pointer;
    background: transparent;
    color: #000;
    font-weight: bold;
    font-size: 30px;
    width: 30px;
    padding: 0;
    border: 2px solid #000;
    border-radius: 100%;
    height: 30px;
    text-align: center;
    line-height: 25px;
    position: relative;
    &::after {
      content: '\f068 ';
      font-family: 'FontAwesome';
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;
    }
  }
  .decrement {
    border: 0;
    cursor: pointer;
    background: transparent;
    color: #000;
    font-weight: bold;
    font-size: 30px;
    width: 30px;
    padding: 0;
    border: 2px solid #000;
    border-radius: 100%;
    height: 30px;
    text-align: center;
    line-height: 25px;
    position: relative;
    &::after {
      content: '\f067 ';
      font-family: 'FontAwesome';
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;
    }
  }
  .value {
    border: none;
    text-align: center;
    -moz-appearance: textfield;
    max-width: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    background: #fff;
    padding: 5px;
  }
}
.single_pbutton {
  text-align: center;
  margin-top: 15px;
  button {
    cursor: pointer;
    display: inline-block;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: #000;
    line-height: normal;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 15px 0px;
    border-radius: 7px;
    width: 85%;
  }
  a {
    cursor: pointer;
    display: inline-block;
    border: none;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: #000;
    line-height: normal;
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 15px 0px;
    border-radius: 7px;
    width: 85%;
  }
}
.mitems_box {
  margin-top: 20px;
  padding-bottom: 30px;
  overflow: hidden;
  background: #fff;
}
.mitems_list {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-weight: 500;
      padding: 8px 0px;
      span {
        &:first-child {
          color: #8f8f8f;
        }
      }
    }
  }
  > h1 {
    font-size: 14px;
    padding-left: 52px;
    padding-bottom: 10px;
  }
}
.left_mitems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  h4 {
    &:first-child {
      padding-right: 30px;
    }
    &:last-child {
      color: #8f8f8f;
    }
  }
}
.total_amount {
  ul {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}
.services_content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-radius: 7px;
  }
  img {
    width: 21px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 10px;
  }
  svg {
    width: 21px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 10px;
  }
  i {
    font-size: 35px;
    line-height: normal;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 11;
    color: #d6d6d6;
  }
}
.museum_content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  a {
    -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    background: #fff;
    border-radius: 7px;
  }
  img {
    width: 28px;
    height: 28px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 10px;
  }
  svg {
    width: 28px;
    height: 28px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 10px;
  }
  i {
    font-size: 35px;
    line-height: normal;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 11;
    color: #000000;
  }
}
.add_class.add_class_hidden {
  overflow: hidden;
}
.mtabs_items_area.add_class_hidden {
  overflow: hidden;
}
#mtabs.mtabs_relative {
  position: relative;
  left: 54%;
}
.services_area.sr_tab_area {
  padding-top: 25px;
  padding-bottom: 60px;
}
.museum_area.sr_tab_area {
  padding-top: 25px;
  padding-bottom: 60px;
}
.sr_tab {
  .tabs_menu {
    ul {
      margin-bottom: 30px;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        background: #cfcfcf;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      li {
        a {
          padding-bottom: 15px !important;
          font-weight: 400 !important;
          text-transform: uppercase !important;
          font-size: 16px !important;
        }
      }
    }
  }
  .new_page_menu {
    .ui-tabs-active.ui-state-active {
      a {
        &::after {
          width: 100% !important;
        }
      }
    }
  }
}
.sr_head {
  p {
    color: #000000;
    font-size: 16px;
  }
}
.squantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.qty-minus {
  border: 2px solid #333333;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  line-height: normal;
  padding: 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.qty-plus {
  border: 2px solid #333333;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  line-height: normal;
  padding: 0;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.qty {
  border: none;
  margin: 0px 5px;
  width: 50px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  background: #ffffff;
}
.logo.add_logo {
  padding-left: 0 !important;
}
.message_area {
  padding-top: 30px;
  padding-bottom: 60px;
}
.message_box {
  > h1 {
    font-weight: 400;
    font-size: 30px;
  }
  > p {
    font-size: 18px;
    max-width: 300px;
    padding-top: 12px;
    margin-bottom: 5px;
  }
}
.login_box {
  > h1 {
    font-weight: 400;
    font-size: 30px;
    padding-bottom: 10px;
  }
}
.report_message_box {
  > h1 {
    font-weight: 400;
    font-size: 30px;
  }
  p {
    position: relative;
    padding-top: 30px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 16px;
    color: #00d562;
    max-width: 325px;
    &::after {
      content: '\f05d ';
      font-family: 'FontAwesome';
      position: absolute;
      left: 0;
      top: 0;
      font-size: 20px;
      color: #00d562;
    }
  }
}
.mssg_button {
  margin-top: 10px;
}
.login_area {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.sin_input.ha_login_input {
  margin-top: 20px;
}
.sin_input {
  p {
    font-size: 13px;
    color: black;
    // margin-bottom: 5px;
    font-weight: 500;
  }
}
.login_button {
  margin-top: 27px;
}
.report_message_area {
  padding-top: 80px;
  padding-bottom: 80px;
}
.popup_area {
  width: 100%;
  padding: 0 !important;
  margin: 0 auto;
  border: none;
  top: 100px;
  .modal-dialog {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .modal-content {
    background-color: #fff;
    border: none;
    padding: 10px;
    border-radius: 10px;
  }
}
.close_icon {
  button {
    background: transparent;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    line-height: 0;
    z-index: 1;
    &:hover {
      background: transparent;
      color: transparent;
      opacity: 1;
    }
  }
}
.close_icon__visible-on-hover {
  button {
    background: transparent;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    line-height: 0;
    z-index: 1;
    &:hover {
      color: initial !important;
      opacity: 0.5;
    }
  }
}
.popup_content {
  padding: 20px 20px 20px 20px;
  > p {
    font-size: 16px;
    color: #333333;
    max-width: 220px;
    padding-bottom: 10px;
  }
}
.pop_input {
  margin-top: 5px;
  p {
    font-size: 16px;
    color: #333333;
  }
}
.ha_login_input.dark_label {
  label {
    font-size: 16px;
    color: #333333;
  }
}
.pop_button {
  margin-top: 15px;
}
.cooking_style {
  h1 {
    text-transform: capitalize;
  }
}
.cooking_style_list {
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: #d8d8d8;
      width: 150%;
      height: 1px;
    }
    li {
      display: block;
      position: relative;
      width: 100%;
      margin: 3px 0px;
      &:first-child {
        margin-top: 10px !important;
      }
      &:last-child {
        margin-bottom: 0 !important;
      }
      input[type='radio'] {
        position: absolute;
        visibility: hidden;
      }
      label {
        display: block;
        width: 100%;
        position: relative;
        z-index: 9;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        line-height: normal;
        padding: 10px 0px;
      }
      .check {
        display: block;
        position: absolute;
        border: 2px solid #000000;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        line-height: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        &::before {
          display: block;
          position: absolute;
          content: '';
          border-radius: 100%;
          width: 10px;
          height: 10px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
  }
  input[type='radio'] {
    &:checked {
      ~ {
        .check {
          &::before {
            background: #000000;
          }
        }
      }
    }
  }
}
.chat_area {
  padding-top: 30px;
}
.chating_box {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 130px;
}
.chating_content {
  ul {
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    li {
      display: inline-block;
      width: 70%;
      .left_chat_text {
        p {
          color: #2c2c2c !important;
        }
      }
      .right_chat_text {
        p {
          color: #ffffff !important;
        }
      }
      p {
        font-size: 18px !important;
      }
    }
    li.left_chat_items {
      text-align: left;
      padding: 5px 0px;
      margin: auto 0 0 0;
    }
    li.right_chat_items {
      text-align: right;
      padding: 20px 0px;
      margin: 0 0 0 auto;
    }
  }
}
.left_chat_text {
  background: #e5e5ed;
  padding: 12px 25px;
  border-radius: 40px;
  float: left;
  text-align: left;
}
.right_chat_text {
  background: #333333;
  padding: 12px 25px;
  border-radius: 40px;
  float: right;
  text-align: left;
}
.text_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 25px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.text_input {
  flex: 0 0 87%;
  max-width: 87%;
  width: 100%;
  input {
    resize: none;
    background: #efefef;
    border: none;
    padding: 15px 15px;
    line-height: normal;
    width: 100%;
    font-weight: 400;
    border-radius: 50px;
    color: #8f8f8f;
    font-size: 16px;
    &::placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
  }
}
.send_button {
  flex: 0 0 13%;
  max-width: 13%;
  width: 100%;
  text-align: right;
  button {
    text-align: center;
    border: none;
    padding: 0;
    width: 45px;
    height: 45px;
    background: #333;
    color: #fff;
    line-height: 35px;
    font-size: 20px;
    border-radius: 100%;
    cursor: pointer;
  }
}
.status_area {
  padding-top: 20px;
  padding-bottom: 30px;
}
.cleaning_date {
  position: relative;
}
.date_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  color: #aaa;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
}
.ui-widget.ui-widget-content {
  position: relative;
  z-index: 9999;
}
.ui-timepicker-standard {
  a {
    font-size: 14px;
    font-weight: 400;
    font-family: 'IBM Plex Sans', sans-serif;
    text-align: left;
  }
}
.ui-datepicker-prev {
  &:before {
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: '\f100';
  }
}
.ui-datepicker-next {
  &:before {
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    content: '\f101';
  }
}
.single_adventure_sports {
  margin-bottom: 30px;
}
.adventure_sports_img {
  width: 100%;
  height: 250px;
  overflow: hidden;
  img {
    width: 100%;
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }
}
.adventure_sports_discrip {
  padding-top: 20px;
  h1 {
    font-weight: 500;
  }
  h4 {
    font-weight: 500;
    padding: 10px 0px;
  }
  p {
    color: #000000;
  }
}
.travel_desk_box {
  background: #ffffff;
  overflow: hidden;
  padding-left: 25px;
  padding-right: 25px;
}
.travel_desk_place {
  margin-left: -25px;
  margin-right: -25px;
  height: 400px;
  overflow: hidden;
  img {
    width: 100%;
    height: 400px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.travel_desk_discrip {
  h1 {
    font-weight: 400;
    font-size: 24px;
  }
  h4 {
    font-weight: 500;
    font-size: 24px;
  }
}
.select_date_travel_area {
  padding-top: 30px;
  padding-bottom: 30px;
  form {
    > p {
      padding-bottom: 10px;
      font-size: 18px;
      color: #333333;
    }
  }
}
.date_area {
  position: relative;
  input {
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: 8px 0px;
    width: 100%;
    padding: 10px 15px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    color: #8f8f8f;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-webkit-input-placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &::-moz-placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &::placeholder {
      color: #8f8f8f;
      opacity: 1;
    }
    &:focus {
      border: 1px solid #d8d8d8;
    }
  }
  i {
    right: 15px;
  }
}
.dt_input {
  select {
    cursor: pointer;
    position: relative;
    width: 100%;
    margin: 8px 0px;
    width: 100%;
    padding: 10px 15px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    color: #8f8f8f;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../images/img/down_arrow.png);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 97% center;
    &:focus {
      border: 1px solid #d8d8d8;
    }
  }
}
.cs_page_location {
  .cs_single_pl {
    p {
      &::before {
        content: '\f101';
        font-family: 'FontAwesome';
        position: absolute;
        left: 0;
        top: 1px;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}
.travel_content {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  &::after {
    position: absolute;
    content: '';
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    border-top: 1px solid #707070;
    width: 150%;
  }
}
.single_travel_content {
  padding-top: 30px;
  &:first-child {
    padding-top: 0px;
  }
  h1 {
    color: #777777;
    padding-bottom: 10px;
  }
  p {
    color: #777777;
    font-size: 16px;
  }
}
.platform_area {
  padding-top: 80px;
  padding-bottom: 80px;
}
.platform {
  > p {
    color: #000000;
    font-size: 20px;
  }
}
.platform_items {
  a {
    display: block;
    padding: 25px 25px;
    background: white;
   
    position: relative;
    z-index: 1;
    &::after {
      position: absolute;
      content: '';
      background-size: cover !important;
      background-repeat: no-repeat !important;
      width: 60%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
.platform_items_box {
  padding-top: 40px;
  .col-6 {
    margin-top: 20px;
    // &:first-child {
    //   .platform_items {
    //     a {
    //       &::after {
    //         background: url(../../images/img/w1.png);
    //       }
    //     }
    //   }
    // }
    // &:nth-child(2) {
    //   .platform_items {
    //     a {
    //       &::after {
    //         background: url(../../images/img/gift.png);
    //       }
    //     }
    //   }
    // }
    &:nth-child(3) {
      .platform_items {
        a {
          &::after {
            background: url(../../images/img/w3.png);
          }
        }
      }
    }
    &:nth-child(4) {
      .platform_items {
        a {
          &::after {
            background: url(../../images/img/w4.png);
          }
        }
      }
    }
  }
}
.social_logo {
  display: block;
  padding-bottom: 20px;
  img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 40px;
    height: 40px;
  }
}
/* TODO: Remove  **/
.platform_sitems {

  .title{
    padding-bottom: 10px;
    font-size: 14.39px !important;
    font-weight: 500;
    color: #000000;
    text-wrap: nowrap;
  }
  p {
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    
  }
  h5{
    color: #8F8F8F;
    white-space:normal;
  // font-family: IBM Plex Sans;
  letter-spacing: -0.36px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  // line-height: 100%;
  height: 70px;
  margin-bottom: 10px;
  }
  span.social_arrow {
    text-align: center;
    background: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px !important;
    height: 44px !important;
    line-height: 35px;
    border-radius: 100%;
  }
}
.external_content_modal {
  width: 90vw;
  height: 100%;
  margin-top: 60px;
  max-width: none !important;
  .content {
    margin-right: auto;
    margin-left: auto;
    height: 95vh;
  }
}
.search_box {
  position: relative;
  .icon-btn {
    position: absolute;
    right: 0;
    top: 0;
    svg {
      color: #8f8f8f !important;
    }
    &:hover {
      svg {
        color: black !important;
      }
    }
  }
}
.guest-status-card {
  padding: 15px;
  border-radius: 10px;
  background-color: white;
  margin-top: 15px;
  p.time {
    font-size: 0.8rem;
    align-self: flex-start;
  }
  p.completed {
    color: #00d562;
    font-size: 0.8rem;
  }
  p.canceled {
    color: #d50000;
    font-size: 0.8rem;
  }
}
@media all and (max-width: 767px) {
  .platform_items {
    a {
      &::after {
        width: 100%;
      }
    }
  }
  .platform_items_box {
    padding-top: 20px;
  }
  .platform_area {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .adventure_sports_img {
    height: 200px;
    img {
      height: 200px;
    }
  }
  .travel_desk_place {
    height: 300px;
    img {
      height: 300px;
    }
  }
  .travel_desk_discrip {
    h1 {
      font-size: 22px;
      br {
        display: none;
      }
    }
    h4 {
      font-size: 22px;
    }
  }
  .select_date_travel_area {
    padding: 25px 0px;
  }
}
@media all and (max-width: 426px) {
  .travel_desk_place {
    height: 250px;
    img {
      height: 250px;
    }
  }
  .adventure_sports_img {
    height: 180px;
    img {
      height: 180px;
    }
  }
  .platform_items {
    a {
      padding: 15px 15px;
    }
  }
  .close_icon {
    button {
      top: 8px;
      right: 8px;
    }
  }
  .close_icon__visible-on-hover {
    button {
      top: 8px;
      right: 8px;
    }
  }
  .external_content_modal {
    width: 95vw;
    height: 100%;
    margin-top: 50px;
    .content {
      padding: 0;
      height: 98vh;
    }
    .modal-body {
      padding: 5px;
    }
  }

  .home_tab {
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li {
      padding-right: 20px;
      letter-spacing: 1.5px;
      a {
        display: inline-block;
        font-weight: 500;
        font-size: 25px;
        color: #DFDFDF;
        line-height: normal;
        letter-spacing: -2.21px;
        position: relative;
        // padding: 10px 0px;
      }
      &:hover {
        a {
          color: black;
        }
      }
    }
  }
}
}

// Profile section
