#page__book-internal-service {
  padding-top: 0px !important;
  .row > div {
    padding-left: 22px;
    padding-right: 22px;
  }
  .description {
    text-align: justify;
    padding: 15px 0px;
    font-size: 13px;
    font-weight: 400;

    @media (min-width: 768px) {
      padding: 20px 0px;
      font-size: 14px;

    }
  }
  label{
    color: black;
    font-weight: 500;
  }
  .cover_image{
    width: 100%;
    margin: auto;
    max-width: 550px;
    padding: 0px !important;
  }
  .ls-1dot5 {
   
    // letter-spacing: 1.5px;
    font-size: 14px !important;
    span{
      color: #27272abf;
      font-size: 14px !important;
    }
  }
  .title{
    font-size: 16px !important;
  }

  .font-small {
    font-size: 13px;
  }

  .text-spaced-large {
    // color: rgb(167, 118, 118);
    color: black;
    font-weight: 500;
    font-size: 13px;
  }

  button {
    min-width: 80px;
  }
  .title{
    display: flex;
    justify-content: space-between;
    span,h5{
      font-size: 16px;
      font-weight: 500;
      color: black;
      max-width: 160px;
    }
 
  }

  .service_time{
    display: flex;
    gap: 10px;
    overflow-x: auto;
    margin-top: 5px ;
    cursor: pointer;
    font-size: 14px;
    position: relative;


 .time_border_inactive{
      border :1px solid  #DDDDE3;
      border-radius:18px;
      padding: 3px 10px;

   
    }
    .time_border_active{
      border :1px solid  #1A94FF;
      border-radius:18px;
      padding: 3px 10px;
      color: #1A94FF;
      font-weight: 500;
    color: black;


   
    }
    
  }
  .service_time::-webkit-scrollbar{
    width: 0px;
    height: 0px;

  }
  .service_time::-webkit-scrollbar-thumb {
    width: 0px;
    height: 0px;

  }
  .service_time::-webkit-scrollbar-track {
  width: 0px;
  height: 0px;
  }

  .ha_booking_input {
    input {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      background: white !important;
      padding: 12px 15px;
      margin: 7px 0px;
      border: 1px solid #ebebeb !important;
      border-radius: 5px;
      resize: none;
      &::-webkit-input-placeholder {
        color: #bababa;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: #bababa;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: #bababa;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: #bababa;
        opacity: 1;
      }
      &::placeholder {
        color: #bababa;
        opacity: 1;
      }
      &:focus {
        border: 1px solid #ccc;
      }
    }
    textarea {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      background: white !important;
      padding: 12px 15px;
      margin: 7px 0px;
      border: 1px solid #ebebeb !important;
      border-radius: 5px;
      resize: none;
      &::placeholder {
        color: #bababa;
        opacity: 1;
      }
      &:focus {
        border: 1px solid #ccc;
      }
    }
    select {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      background: white !important;
      padding: 12px 15px;
      margin: 7px 0px;
      border: 1px solid #ebebeb !important;
      border-radius: 5px;
      resize: none;
    }
  }

.order_confirmation{
  background-color: #f5f5f5;
}

.book_input{
  font-weight: 600;
  padding: 7px 20px !important;
}
.confirm_button{
font-weight: 600;
padding: 7px 20px !important;
}

}

.service_img{

  // border-radius: 0 0 10px 10px;

  width: 100%;
  margin: auto;
  max-width: 1400px;
  background-color: #1A94FF;
}


.background_color{
  background-color: white !important;
}
.background_color_onSubmiting{
  background-color: rgb(170, 60, 60) !important;
}