.navigation-card {
  padding: 0 !important;
  span.social_arrow {
    text-align: center;
    background: #484848;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px !important;
    height: 44px !important;
    line-height: 29px;
    border-radius: 100%;
  }
  .bg_secondary{
    background-color: #d63636 !important;
  }
  &.xy-stacked {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top:6px !important;

    .navigation-card__background {
      // position: absolute;
      content: '';
      background-size: contain !important;
      background-repeat: no-repeat !important;
      width: 100%;
      height: 60%;
      left: -10%;
      top: -15%;
      z-index: -5;
      opacity: 0.05;
    }

    .bg_secondary{
      background-color: #d63636 !important;
    }
    .navigation-card__container {
      background: white;
      // border-radius: 10px;
      overflow: hidden;
      a {
        display: block;
        padding: 15px 15px;
        position: relative;
        z-index: 5;
      }
    }

    .link-container {
      p {
        // margin-top: 10px;
        margin-bottom: 5px;
        font-size: 14.39px;
        line-height: 25px;
        height: 40px;
        color: black;
        font-weight: 500;
        display: flex;
        align-items: flex-end;

        @media screen and (min-width: 376px) {
          // margin-top: 10px;
        }
      }
h5{
  color: #8F8F8F;
  white-space:normal;
// font-family: IBM Plex Sans;
letter-spacing: -0.36px;
font-size: 13px;
font-style: normal;
font-weight: 400;
// line-height: 100%;
height: 55px;
margin-bottom: 10px;
}
      img.main-image {
        display: block;
        width: 40px;
        height: 40px;
      }
    }

    .platform_items_box {
      padding-top: 40px;

      .col-6 {
        margin-top: 20px;
      }
    }
  }

  &.y-stacked {
    padding-left: 3px !important;
    padding-right: 3px !important;

    &.xs-position-fixed-bottom {
      position: fixed;
      bottom: 20px;

      @media screen and (min-width: 376px) {
        position: initial;
      }
    }

    .navigation-card__container {
      margin-top: 15px !important;
      border-radius: 5px;
      padding: 15px;
      background-color: white;
      display: block;
      font-size: 18px;

      .link-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 16px;
          color: black;
          font-weight: 400;
        }
        h5{
          color: #000;
          // font-family: IBM Plex Sans;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 5px;
        }
      }
    }
  }

  &:hover {
    .navigation-card__container {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    span.social_arrow {
      background-color: black;
    }
  }
}
