#loading-screen {
	height: calc(100vh - 0px);
	justify-content: center;
	align-items: center;
	#splash_logo {
		width: 300px;
		height: 45px;
		background-position-x: center;
		margin-bottom: 30px;
	}
	.logo_spinner_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
#not-found-screen {
	height: calc(100vh - 0px);
	justify-content: center;
	align-items: center;
	align-items: flex-start;
	padding-top: 100px;
	#splash_logo {
		width: 300px;
		height: 45px;
		background-position-x: center;
		margin-bottom: 30px;
		margin-bottom: 100px;
	}
	.logo_spinner_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	p {
		font-size: 16px;
	}
	.recent-tenant-card {
		flex-basis: 30%;
		padding: 15px;
		border-radius: 10px;
		&:hover {
			cursor: pointer;
			box-shadow: 3px 3px 4px 4px rgba(0, 0, 0, 0.1);
			background: white;
			a {
				text-decoration: underline;
			}
		}
		h4 {
			font-size: 16px;
			font-weight: bold;
		}
	}
}
#splash_logo {
	color: purple;
	float: left;
	background: transparent url(../../images/logo-2.svg) 0 0 no-repeat;
	background-size: contain;
	height: 30px;
	width: 200px;
	padding: 0;
	margin: 0;
	margin-bottom: 16px;
	text-indent: -9999px;
}
.quantity_counter {
	.decrement {
		&::after {
			content: '\f068 ';
			font-family: 'FontAwesome';
			position: absolute;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			font-size: 20px;
		}
	}
	.increment {
		&::after {
			content: '\f067 ';
			font-family: 'FontAwesome';
			position: absolute;
			-webkit-transform: translateX(-50%) translateY(-50%);
			-ms-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);
			font-size: 20px;
		}
	}
}
.app_show_more_action {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
.app_load_more {
	margin-top: 10px;
	text-align: center;
	padding-bottom: 20px;
}
.show_more_new{
	color: black !important;
	font-weight: 500 !important;
	font-size: 14px !important;
}
.modal-title {
	padding-right: 50px;
	font-size: 16px;
}
.cooking_style {
	h1 {
		text-transform: none;
	}
}
.modal-dialog {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
}
.ha_login_input {
	input {
		padding: 12px 15px;
	}
	textarea {
		padding: 12px 15px;
	}
}
.form_input_color {
	background: #f4f4f4;
}
.nav-box {
	margin-top: 20px;
}
.txt-capitalize {
	text-transform: capitalize;
}
.avendi-modal {
  top: 30px;
  left: 10px;
  width: 95%;
  height: 95%;

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 0;

    .popup_content {
      padding-bottom: 0;
    }
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;
    justify-content: start;
  }
}
@media all and (max-width: 600px) {
	#not-found-screen {
		#splash_logo {
			margin-bottom: 75px;
		}
		.recent-tenant-card {
			flex-basis: 50%;
		}
	}
}
