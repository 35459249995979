.proile_banner {
	img {
		width: 100%;
		// height: 60vh;
height: 402px;
		object-fit: cover;
		// border-radius: 10px;
		// margin-top: -40px;
	}
}
.profile_article{
	background-color: white;
	padding: 15px;
	margin-bottom: 15px;
	article{
		overflow-x: auto;
		gap: 10px;
	}
	img{
		border-radius: 8px 8px 0 0;
		width: 300px;
		height: 252px;
		object-fit: cover;
	}

	
	h2{
		font-size: 20px;
		color: black;
		font-weight: 600;
		
	}
	h3{
		font-size: 17px;
		color: black;
		font-weight: 400;
		
	}
	h4{
		font-size: 16px;
		color: black;
		font-weight: 600;
	}
	h5{
		font-size: 14px;
		color: black;
	}
}
.properties_card{
	img{

		width: 250px;
	height: 232px;
	}
		border-radius: 8px;
	border: 1px solid #F2F2F2;
	background: #FFF;
	// box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.all_properties{
    margin:0px 10px;
    border-radius: 8px;
	border: 1px solid #F2F2F2;
	background: #FFF;
	
    // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
    img{
		border-radius: 8px 8px 0 0;
	
	}
    h4{
		font-size: 18px;
		color: black;
		font-weight: 600;
	}
}


.all_services{
	// margin:0px 10px;
	border-bottom: 0.1px solid rgba(168, 160, 160, 0.3);
	// border-bottom: 1px solid black;
	// background: #FFF;
    // box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);

    h4{
		font-size: 18px;
		color: black;
		font-weight: 600;
	}
}


.our_service{
	padding:0 15px 0 15px;

	h2{
		font-size: 20px;
		color: black;
		font-weight: 600;
		
	}
}

.cursor_pointer{
	cursor: pointer;
}

.profile_icons{
	width: 20px;
height: 20px;
	margin-right: 10px;
	opacity: 0.7;
}