#page__internal-service {
  // font-family: 'Work sans' !important;
  .avendi-card {
    padding: 20px 0px;
    border-radius: 0px;
    border-bottom: 1px solid rgba(168, 160, 160, 0.3);
    margin:  0px;

    h4 {
      font-size: 16px;

      &.title {
        flex: 3;
        text-align: left;
        font-size: 14px;
        // font-family: sans-serif;
        font-weight: 800;
      max-width: 160px;

      }

      &.price {
        text-align: right;
        font-size: 14px;
        // font-family: sans-serif;
        font-weight: 800;
      }
    }

    .btn-outline-dark {
      &:hover {
        background-color: #343a40;
        color: white;
      }
    }
    .btn-book{
      padding: 7px 12px;
      background-color: #343a40;
      color: white;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
    }
    .description{
      // margin-top: -10px;
      max-width: 160px;
      margin-top: 10px;

    }
    @media screen and (min-width :351px){
      .description{
        max-width: 200px;
  
      }
    }
  }
}
