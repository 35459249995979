@media all and (max-width: 767px) {
	.menu {
		ul {
			li {
				a {
					padding-left: 30px;
				}
			}
		}
	}
	.menu_bottom {
		padding-left: 30px;
	}
	.sr_tab {
		.tabs_menu {
			ul {
				padding-bottom: 1px;
			}
		}
	}
	.message_box {
		>p {
			font-size: 16px;
		}
	}
}
@media all and (max-width: 500px) {
	.single_tabs_items {
		padding: 15px 15px 60px 15px;
	}
	.hotels_gallery {
		.single_tabs_items {
			padding: 15px 10px 25px 10px;
		}
	}
	.tabs_items_img {
		img {
			width: 100px;
			height: 90px;
		}
	}
	.tabs_menu {
		.arrow.arrow-left {
			left: -10px;
			top: -14px;
		}
		.arrow.arrow-right {
			right: -10px;
			top: -15px;
		}
		ul {
			li {
				padding-left: 7px;
				padding-right: 7px;
				a {
					font-size: 15px;
				}
			}
		}
	}
	.menu_bottom {
		width: 95%;
	}
	.new_page_tabs_area {
		padding-top: 20px;
	}
	.sr_tab {
		.tabs_menu {
			ul {
				li {
					a {
						font-size: 11px !important;
					}
				}
			}
		}
	}
	.sr_head {
		p {
			font-size: 14px;
		}
	}
	.qty {
		font-size: 14px;
	}
	.qty-minus {
		width: 22px;
		height: 22px;
		font-size: 14px;
	}
	.qty-plus {
		width: 22px;
		height: 22px;
		font-size: 14px;
	}
	.chating_content {
		ul {
			li {
				p {
					font-size: 16px !important;
				}
			}
		}
	}
	.text_input {
		input {
			font-size: 16px !important;
		}
	}
}
@media all and (max-width: 450px) {
	h1 {
		font-size: 16px;
	}
	h4 {
		font-size: 12px;
	}
	p {
		font-size: 12px;
	}
	.time_items {
		h3 {
			font-size: 12px;
		}
	}
	.page_list {
		ul {
			li {
				font-size: 12px;
			}
		}
	}
	.menu_bottom {
		h4 {
			font-size: 13px;
		}
	}
	.new_page_left {
		p {
			padding: 15px 0px;
		}
	}
	.menu {
		padding-top: 110px;
	}
	.mitems_list {
		>h1 {
			font-size: 12px;
			padding-left: 49px;
		}
	}
	.text_input {
		flex: 0 0 86%;
		max-width: 86%;
	}
	.send_button {
		flex: 0 0 14%;
		max-width: 14%;
	}
	.add_class2 {
		textarea {
			&::placeholder {
				font-size: 14px;
			}
		}
	}
}
@media all and (max-width: 426px) {
	.tabs_items_img {
		img {
			width: 80px;
			height: 70px;
		}
	}
	.menu {
		ul {
			li {
				a {
					font-size: 15px;
				}
			}
		}
	}
	.services_content {
		img {
			width: 21px;
			height: 22px;
		}
		svg {
			width: 21px;
			height: 22px;
		}
	}
	.museum_content {
		img {
			width: 25px;
			height: 25px;
		}
		svg {
			width: 25px;
			height: 25px;
		}
	}
	.menu_bottom {
		h4 {
			font-size: 12px;
		}
	}
	.text_input {
		flex: 0 0 85%;
		max-width: 85%;
	}
	.send_button {
		flex: 0 0 15%;
		max-width: 15%;
	}
}
@media all and (max-width: 400px) {
	.tabs_menu {
		ul {
			li {
				a {
					font-size: 11px;
				}
			}
		}
	}
	.menu {
		ul {
			li {
				a {
					padding-left: 20px;
				}
			}
		}
	}
	.menu_bottom {
		padding-left: 20px;
		width: 100%;
		img {
			width: 45px;
		}
	}
	.text_input {
		flex: 0 0 83%;
		max-width: 83%;
	}
	.send_button {
		flex: 0 0 17%;
		max-width: 17%;
	}
}
@media all and (max-width: 375px) {
	.sr_tab {
		.tabs_menu {
			ul {
				li {
					a {
						font-size: 11px !important;
					}
				}
			}
		}
	}
}
@media all and (max-width: 330px) {
	.sr_tab {
		.tabs_menu {
			ul {
				li {
					a {
						font-size: 11px !important;
					}
				}
			}
		}
	}
	.text_input {
		flex: 0 0 82%;
		max-width: 82%;
	}
	.send_button {
		flex: 0 0 18%;
		max-width: 18%;
	}
}
