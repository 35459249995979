.card_title{
    // font-family: 'Inter';
    margin: 30px 0px;
    line-height: 44px;
    letter-spacing: -1.5px;
    h1,h2{
        margin: -5px !important;
        // letter-spacing: -2.5px;
    }
    h2{
        color:"#B6B6B6";
        font-size: 42px !important;
        font-weight: 400;
    }
    h1{
        color:black;
        font-size: 42px !important;
        font-weight: 500;
    }
    .sub_title{
        display: flex;
        gap: 10px;
        margin-top: 18px;
        justify-items: end;
        align-items: end;
    div{
    display: flex;
    span{
        font-size: 14px;
        line-height: 20px;
        color: black;
        letter-spacing: -0.5px;
    
    }
    }
    .line{
        border-bottom: 1px solid black;
        background-color: black;
        height: 1px;
        width: 130px;
    }
    }

    .sub_title_home_page{
        align-items: center;
    }
}






