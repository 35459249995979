.unread-message-content {
    font-size: 16px; /* Sets a readable font size */
    color: #534f4f;
}

.msg-button-section {
    display: flex;
    gap: 15px; /* Adds spacing between buttons */
    margin-top: 30px; /* Adds space above the button section */
  }
  
  .msg-button-section button {
    padding: 5px 10px; /* Adds padding for better click area */
    font-size: 14px; /* Sets a readable font size */
    border: none; /* Removes default border */
    border-radius: 5px; /* Adds rounded corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth hover animation */
  }
  
  .msg-button-section button:first-child {
    background-color: #4caf50; /* Green background for Reply button */
    color: white; /* White text color */
  }
  
  .msg-button-section button:first-child:hover {
    background-color: #45a049; /* Darker green on hover */
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .msg-button-section button:last-child {
    background-color: #2196f3; /* Blue background for Mark as Read button */
    color: white; /* White text color */
  }
  
  .msg-button-section button:last-child:hover {
    background-color: #1e88e5; /* Darker blue on hover */
    transform: scale(1.05); /* Slight zoom effect on hover */
  }